import { apiGet_rate, apiQueryRateUSDByTargetCurrency } from '@/resource'
import mixin from '@/mixins'
import rounding from '@/util/rounding'

export default {
  mixins: [mixin],
  data() {
    return {
      loading: false,

      accountCurrency: null, // for Japanese yen which no decimal point
      minLimit: this.$config.depositDefaultAmountMin,
      maxLimit: this.$config.depositDefaultAmountMax,
      minLimitByUSC: this.$config.depositUSCAmountMin,
      maxLimitByUSC: this.$config.depositUSCAmountMax,
      maxUSCByRate: 0,
      phpCountryCode: 4131, // Philippines
      payments: {
        solidpayment: { paymentType: 1, paymentChannel: 22 },
        apgDeposit: { paymentType: 1, paymentChannel: 5 },
        sdPayDeposit: { paymentType: 1, paymentChannel: 6 },
        virtualPayDeposit: { paymentType: 1, paymentChannel: 9 },
        worldcardDeposit: { paymentType: 1, paymentChannel: 14 },
        maxisPayDeposit: { paymentType: 1, paymentChannel: 17 },
        bridgerPayDeposit: { paymentType: 1, paymentChannel: 8 },
        internationalSwift: { paymentType: 2, paymentChannel: 1 },
        internationalEqualsMoney: { paymentType: 2, paymentChannel: 1 },
        thailandZotaPay1: { paymentType: 4, paymentChannel: 1 },
        thailandXpay: { paymentType: 4, paymentChannel: 6 },
        malaysiaZotapay: { paymentType: 5, paymentChannel: 1 },
        malaysiaPaytrust: { paymentType: 5, paymentChannel: 2 },
        malaysiaXpay: { paymentType: 5, paymentChannel: 3 },
        fasaPay: { paymentType: 7, paymentChannel: 1 },
        vietnamZotapay: { paymentType: 8, paymentChannel: 1 },
        vietnamXpay: { paymentType: 8, paymentChannel: 2 },
        mobilePay: { paymentType: 9, paymentChannel: 1 },
        unionPayChina: { paymentType: 13, paymentChannel: 1 },
        bitcoin: { paymentType: 15, paymentChannel: 1 },
        usdt2: { paymentType: 15, paymentChannel: 2 },
        usdt4: { paymentType: 15, paymentChannel: 4 },
        bitcoinCPS: { paymentType: 15, paymentChannel: 1 },
        omniCPS: { paymentType: 15, paymentChannel: 2 },
        usdt_cps: { paymentType: 15, paymentChannel: 4 },
        canadaLocalBank: { paymentType: 16, paymentChannel: 1 },
        sticPay: { paymentType: 17, paymentChannel: 1 },
        indonesiaZotapay: { paymentType: 18, paymentChannel: 1 },
        // indonesiaBankwire: { paymentType: 18, paymentChannel: 2 },
        indonesiaXpay: { paymentType: 18, paymentChannel: 3 },
        dragonPhoenixDeposit: { paymentType: 19, paymentChannel: 1 },
        bitwalletDeposit: { paymentType: 20, paymentChannel: 1 },
        philipinessPayment: { paymentType: 25, paymentChannel: 1 },
        // astropayDeposit: { paymentType: 27, paymentChannel: 1 },
        // southAfricaPayment: { paymentType: 29, paymentChannel: 1 },
        // nigeriaZotapay: { paymentType: 12, paymentChannel: 1 },
        localdepositor: { paymentType: 55, paymentChannel: 1 },
      },
      originalMinLimit: 0,
    }
  },
  computed: {
    currencyType() {
      switch (this.accountCurrency) {
        case 'USD':
          return '$'
        case 'USC':
          return '￠'
        default:
          return '$'
      }
    },
    paymentList() {
      return this.$store.state.payment.paymentList
    },
    USCrate() {
      return this.accountCurrency === 'USC' ? this.rate * 100 : this.rate
    },
    USCMinLimit() {
      return this.accountCurrency === 'USC' ? this.minLimitByUSC : this.minLimit
    },
    USCMaxLimit() {
      return this.accountCurrency === 'USC' ? this.maxLimitByUSC : this.maxLimit
    },
    countryCode() {
      return this.$store.state.common.countryCode
    },
  },
  watch: {
    accountCurrency: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.convertUSDToTargetCurrency(newValue)
        }
      },
    },
  },
  mounted() {
    this.getMinMaxAmount()
  },
  methods: {
    // for Japanese yen which no decimal point
    setCurrency(currency) {
      this.accountCurrency = currency
    },
    getMaxUSCByRate(maxLimit = 0, rate = 0) {
      if (this.maxUSCByRate) return this.maxUSCByRate
      const maxUSCByRate = rounding(Math.floor, (maxLimit / rate) * 100, 2)
      this.maxUSCByRate =
        maxUSCByRate > this.maxLimitByUSC || maxUSCByRate === '0.00' ? this.maxLimitByUSC : maxUSCByRate
      return this.maxUSCByRate
    },
    getRate(rateChange = 0) {
      return this.accountCurrency === 'USC' ? rounding(Math.floor, rateChange / 100, 2) : rateChange
    },
    getMinMaxAmount() {
      //if Philippines, set to 5000
      this.minLimitByUSC =
        this.countryCode == this.phpCountryCode ? this.$config.depositUSC5000Amount : this.$config.depositUSCAmountMin

      const payment = this.payments[this.$route.name + (this.$route.params.id || '')]
      if (payment) {
        const paymentMethod = this.paymentList.find(
          f => f.paymentType === payment.paymentType && f.paymentChannel === payment.paymentChannel
        )
        if (paymentMethod) this.setMinMaxAmount(paymentMethod)

        this.originalMinLimit = this.minLimit
      }
    },
    setMinMaxAmount(paymentMethod) {
      this.minLimit = paymentMethod.minAmount
      this.maxLimit = paymentMethod.maxAmount
      this.minLimitByUSC = paymentMethod.minUscAmount
      this.maxLimitByUSC = paymentMethod.maxUscAmount
    },
    fetchRate(value) {
      apiGet_rate({
        mt4_account: value,
      })
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data
          else this.errorMessage('Get error when fetching rate. Please fresh the page and try again later')
        })
        .catch(err => {
          this.errorMessage('Get error when fetching rate. Please fresh the page and try again later')
        })
    },
    depositSubmitWithRate(result, callback) {
      if (result.data.code == 0 && result.data.data) {
        callback(result.data.msg)
      } else if (result.data.code !== 562) {
        this.loading = false
        this.errorMessage(this.$t('deposit.default.failed'))
      } else {
        this.loading = false
      }
    },
    setAmtLabel(currency, amount) {
      return currency === 'USC'
        ? `${this.$t('common.field.amt')}    ${this.$t('common.field.actualAmount')}= ${amount / 100} USD`
        : this.$t('common.field.amt')
    },
    convertUSDToTargetCurrency(currency, equalValue = this.originalMinLimit || this.$config.depositDefaultAmountMin) {
      const apiCurrencyList = this.$config.usdRateFromApiCurrencyList
      if (!apiCurrencyList.includes(currency)) {
        this.minLimit = equalValue
        return
      }

      return apiQueryRateUSDByTargetCurrency(currency)
        .then(res => {
          if (res.data.code === 0 && res.data.data) {
            this.minLimit = equalValue * Number(res.data.data)
          } else {
            this.minLimit = equalValue
          }
        })
        .catch(error => {
          this.minLimit = equalValue
        })
    },
  },
}
